import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Coming Soon
        </p>
        <span class="one">.</span><span class="two">.</span><span class="three">.</span>
      </header>
    </div>
  );
}

export default App;
